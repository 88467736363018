import React, { useState, useEffect } from "react";
import {
  DealBuilderApi,
  AppHeaderComponent,
  LoadingComponent,
  ApiLoaderComponent,
} from "@unity/components";
import { Box, Stack } from "@mui/material";
import { CanUpdate } from "../../services/Permissions";
import ModuleFunctions from "../../modules/ModuleFunctions";
import ProductLines from "../../modules/ProductLines";
import FullScreenDialogBox from "../../modules/FullScreenDialogBox";
import DatePicker from "../../common/DatePicker";
import ProductOverview from "../../modules/ProductOverview";
import SaveWarningDialogBox from "../../common/SaveWarningDialog";
import OptionalDocsDialogBox from "../../modules/OptionalDocsDialogBox";
import Currency from "currency.js";
import SupportingProductDoc from "../../modules/SupportingProductDoc";
import { DynamicOptionsDialog } from "../../modules/dynamicOptionsDialog";

export default function ConfigProduct(props) {
  const [loading, setLoading] = useState({ status: false });
  const [data, setData] = useState(false);
  const [errors, setErrors] = useState(false);
  const [periods, setPeriods] = useState(false);
  const [charges, setCharges] = useState(false);

  const [openFullBox, setOpenFullBox] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [productLineIndex, setProductLineIndex] = useState(false);
  const [optionLineIndex, setOptionLineIndex] = useState(false);
  const [saveWarningDialogBox, setSaveWarningDialogBox] = useState(false);

  const [liveProductDocument, setLiveProductDocument] = useState(false);
  const [liveProductSchedules, setLiveProductSchedules] = useState(false);

  const [optionalDocumentDialogBox, setOptionalDocumentDialogBox] =
    useState(false);
  const [optionalDocData, setOptionalDocData] = useState(false);

  const [structure, setStructure] = useState(false);
  const [bus, setBus] = useState(false);

  const [dynamicOptOpen, setDynamicOptOpen] = useState(false);
  const [quoteOpen, setQuoteOpen] = useState(null);

  const uneditable =
    data.responsible_id !== props.context.auth.contact ||
    data.approved ||
    (data.approved && new Date(data.deal_valid_to) < currentDate) ||
    data.deal_approval;
  const edit = CanUpdate("deal-config");

  const handleCheckBox = (data) => {};

  const handleFileUploadedData = (index, vault) => {
    const dataCopy = { ...data };

    const docsData = {
      id: vault.data[0].id,
      name: vault.data[0].name,
      size: vault.data[0].size,
      raw_type: vault.data[0].raw_type,
      created_at: vault.data[0].created_at,
    };

    if (!data.lines[productLineIndex].options[index]["option_docs"]) {
      dataCopy.lines[productLineIndex].options[index]["option_docs"] = [
        docsData,
      ];
      dealChange({ ...dataCopy, changed: true });
    } else {
      const optionDocsArray =
        dataCopy.lines[productLineIndex].options[index]["option_docs"];
      optionDocsArray.push(docsData);
      dataCopy.lines[productLineIndex].options[index]["option_docs"] =
        optionDocsArray;
      dealChange({ ...dataCopy, changed: true });
    }
  };

  const getProductDocument = async (id, obj) => {
    const res = await DealBuilderApi.liveProductGetDocuments(id, obj);
    if (res.success) {
      setLiveProductDocument(res.data);
    }
  };

  const getProductSchedules = async (id, obj) => {
    const res = await DealBuilderApi.liveProductGetSchedules(id, obj);
    if (res.success) {
      setLiveProductSchedules(res.data);
    }
  };

  const handleDynamicLine = (event, index, change) => {
    //calculateMarkup(cost, index)
    const dataCopy = { ...data };
    if (Array.isArray(event) && event.length > 0) {
      event.forEach((e) => {
        dataCopy.lines[productLineIndex].options[index][e.target.name] =
          e.target.value;
      });
    } else {
      dataCopy.lines[productLineIndex].options[index][event.target.name] =
        event.target.type === "number"
          ? parseInt(event.target.value)
          : event.target.value;
    }
    dealChange({ ...dataCopy, changed: true }, change);
    closeFullBox();
  };

  const handleOpenOptionalDocumentDialogBox = (index) => {
    setOptionalDocData(
      data.lines[productLineIndex].options[index]["option_docs"]
    );
    setOptionLineIndex(index);
    setOpenFullBox(false);
    setOptionalDocumentDialogBox(true);
  };

  const handleCloseOptionalDocumentDialogBox = () => {
    setOptionalDocumentDialogBox(false);
    setOptionLineIndex(false);
    setOpenFullBox(true);
    setOptionalDocData(false);
  };

  const handleOpenSaveWarningDialogBox = () => {
    setSaveWarningDialogBox(true);
  };

  const handleCloseSaveWarningDialogBox = () => {
    setSaveWarningDialogBox(false);
  };

  const closeFullBox = () => {
    setProductLineIndex(false);
    setOpenFullBox(false);
  };

  const handleFullBox = (index) => {
    setProductLineIndex(index);
    setSelectedRow(data.lines[index]);
    setOpenFullBox(true);
  };

  const chargesChange = (data) => {
    setCharges(data);
  };

  const periodsChange = (data) => {
    setPeriods(data);
  };

  const dealChange = (data, update) => {
    setData((prevState) => {
      const newState = {
        ...prevState,
        ...data,
      };
      if (update) handleSave(newState);
      return newState;
    });
  };

  const deleteOptionalDocument = (index) => {
    const dataCopy = { ...data };
    dataCopy.lines[productLineIndex].options[
      optionLineIndex
    ].option_docs.splice(index, 1);
    dealChange({ ...dataCopy, changed: true });
  };

  const getPeriodicData = (pCode) => {
    const result = periods.find((x) => x.id == pCode);
    if (result) {
      return result.name;
    }
    return "N/A";
  };

  const getChargeData = (cCode) => {
    const cResult = charges.find((x) => x.id == cCode);
    if (cResult) {
      return cResult.name;
    }
    return "N/A";
  };

  const structureChange = (data) => {
    setStructure(data);
  };

  const busChange = (data) => {
    setBus(data);
  };

  const getProduct = async () => {
    const res = await DealBuilderApi.getLiveProduct(
      props.route.match.params.id
    );

    if (res.success) {
      setData({ ...res.data, dealChange: dealChange });
      getProductDocument(res.data.id, res.data);
      getProductSchedules(res.data.id, res.data);
    }
  };

  const updateLine = (index, obj, change) => {
    let tempProduct = { ...data };
    tempProduct.lines[index][obj.target.name] = obj.target.value;
    dealChange({ ...tempProduct, changed: true }, change);
  };

  const updateValidToDealDate = (obj) => {
    let selectedDate = { ...data };
    if (obj != null) {
      selectedDate["valid_to"] = obj.$d;
    } else {
      selectedDate["valid_to"] = "";
    }
    dealChange({ ...selectedDate, changed: true });
  };

  const updateValidFromDealDate = (obj) => {
    let selectedDate = { ...data };
    if (obj != null) {
      selectedDate["valid_from"] = obj.$d;
    } else {
      selectedDate["valid_to"] = "";
    }
    dealChange({ ...selectedDate, changed: true });
  };

  const updateValidToDynamicDealDate = (obj, index) => {
    const dataCopy = { ...data };
    let lowest = new Date(obj.$d);

    dataCopy.lines[productLineIndex].options[index]["valid_to"] = new Date(
      obj.$d
    );

    dataCopy.lines[productLineIndex].options.forEach((e) => {
      let dateObj = new Date(e.valid_to);
      if (dateObj < lowest) {
        lowest = dateObj;
      }
    });

    dataCopy["valid_to"] = lowest;

    dealChange({ ...dataCopy, changed: true });
  };

  const updateValidFromDynamicDealDate = (obj, index) => {
    const dataCopy = { ...data };
    let highest = new Date(obj.$d);

    dataCopy.lines[productLineIndex].options[index]["valid_from"] = new Date(
      obj.$d
    );

    dataCopy.lines[productLineIndex].options.forEach((e) => {
      let dateObj = new Date(e.valid_from);
      if (dateObj > highest) {
        highest = dateObj;
      }
    });

    dataCopy["valid_from"] = highest;

    dealChange({ ...dataCopy, changed: true });
  };

  const calculateMarkup = (event, index) => {
    const newData = { ...data };

    const value =
      event.target.value.indexOf(".") >= 0
        ? event.target.value.substr(0, event.target.value.indexOf(".")) +
          event.target.value.substr(event.target.value.indexOf("."), 3)
        : event.target.value;

    newData.lines[productLineIndex].options[index][event.target.name] = value;

    const mat_cost =
      newData.lines[productLineIndex].options[index]["material_cost"];
    const markup = parseFloat(
      newData.lines[productLineIndex].options[index]["markup"]
    );

    const val = getMarkup(mat_cost, markup, index);

    newData.lines[productLineIndex].options[index]["unit_price"] = val.work;

    dealChange({ ...newData, changed: true });
  };

  const getMarkup = (mat_cost, markupDefault) => {
    const GBP = (value) =>
      Currency(value, { symbol: "£", decimal: ".", separator: "," });

    const cost = GBP(mat_cost);

    //FIX THIS: how should we calculate markup?
    const markupVal = GBP(cost).multiply((markupDefault || 0) / 100);
    const work = GBP(cost).add(markupVal);
    const markup = GBP(work).multiply(
      parseFloat(data.lines[productLineIndex]["quantity"]) || 0
    );
    return { work: work.value, markup: markup.value };
  };

  const calculateProductLinePrice = (index, event) => {
    const GBP = (value) =>
      Currency(value, { symbol: "£", decimal: ".", separator: "," });
    const newData = { ...data };

    const value =
      event.target.value.indexOf(".") >= 0
        ? event.target.value.substr(0, event.target.value.indexOf(".")) +
          event.target.value.substr(event.target.value.indexOf("."), 3)
        : event.target.value;

    newData.lines[index][event.target.name] = value;

    const cost = GBP(newData.lines[index]["unit_price"]);
    const total = GBP(newData.lines[index]["price"]);

    const markup = GBP(cost).multiply(
      parseFloat(newData.lines[index]["quantity"]) || 0
    );
    const work = GBP(cost).add(markup);

    newData.lines[index]["price"] = markup.value;

    dealChange({ ...newData, changed: true });
  };

  const handleSave = async (data) => {
    setLoading({ status: true, data: "Saving Product, Please Wait...." });
    const res = await DealBuilderApi.updateLiveProduct(
      props.route.match.params.id,
      data
    );

    if (res.success) {
      setLoading({ status: true, data: "Successfully updated product" });
      setTimeout(() => {
        getProduct();
        setLoading({ status: false });
      }, 3000);
    } else {
      if (res.errors) {
        setErrors(res.errors);
        setLoading({
          status: true,
          data: "Validation Errors!, Please Wait....",
        });
        setTimeout(() => {
          setLoading({ status: false });
        }, 3000);
      } else {
        setLoading({ status: true, data: res.message });
        setTimeout(() => {
          // history.push("/deal-builder/index");
          setLoading({ status: false });
        }, 3000);
      }
    }
  };

  useEffect(() => {
    getProduct();
    ModuleFunctions.getStructure({ structureChange: structureChange });
    ModuleFunctions.getPeriodicCodes({ periodsChange: periodsChange });
    ModuleFunctions.getChargeCodes({ chargesChange: chargesChange });
    ModuleFunctions.getBusinesses({ change: busChange });
  }, []);

  if (data && structure) {
    return (
      <>
        <AppHeaderComponent
          saveBtn={() => (data.changed ? handleSave(data) : null)}
          context={props.context}
          theme={props.context.theme}
          name="deal-config"
          subpage={"product"}
          spacer={true}
          unsavedItems={data.changed}
          showUnsavedWarningPromt={handleOpenSaveWarningDialogBox}
        />

        <ProductOverview
          data={data}
          structure={structure}
          type="view"
          bus={bus}
        />
        <Box sx={{ p: 1, mt: 1, mb: 1 }}>
          <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
            <DatePicker
              date={data.valid_from}
              label="Valid from"
              name={"valid_from"}
              updateDealDate={updateValidFromDealDate}
              uneditable={uneditable}
            />
            <DatePicker
              date={data.valid_to}
              label="Expiry date"
              name={"valid_to"}
              updateDealDate={updateValidToDealDate}
              uneditable={uneditable}
            />
          </Stack>
        </Box>
        <ProductLines
          productLines={data.lines}
          handleFullBox={handleFullBox}
          updateLine={updateLine}
          handleCheckBox={handleCheckBox}
          isDealApproved={data.deal_approval}
          dealChange={dealChange}
          uneditable={uneditable}
          getPeriodicData={getPeriodicData}
          calculateProductLinePrice={calculateProductLinePrice}
          getDynamicOptions={() => setDynamicOptOpen(true)}
          hasApi={data?.has_api}
        />

        {liveProductDocument && liveProductSchedules ? (
          <SupportingProductDoc
            productDocuments={liveProductDocument}
            productSchedules={liveProductSchedules}
          />
        ) : null}

        <FullScreenDialogBox
          data={selectedRow}
          context={props.context}
          dealChange={dealChange}
          getChargeData={getChargeData}
          getPeriodicData={getPeriodicData}
          closeFullBox={closeFullBox}
          openFullBox={openFullBox}
          isDealApproved={data.deal_approval}
          productLineIndex={productLineIndex}
          handleFileUploadedData={handleFileUploadedData}
          updateValidToDynamicDealDate={updateValidToDynamicDealDate}
          updateValidFromDynamicDealDate={updateValidFromDynamicDealDate}
          handleDynamicLine={handleDynamicLine}
          errors={errors}
          handleOpenOptionalDocumentDialogBox={
            handleOpenOptionalDocumentDialogBox
          }
          calculateMarkup={calculateMarkup}
          uneditable={uneditable}
          getDynamicOptions={() => setDynamicOptOpen(true)}
          quoteOpen={quoteOpen}
          setQuoteOpen={setQuoteOpen}
          hasApi={data?.has_api}
          getMarkup={getMarkup}
        />

        <SaveWarningDialogBox
          open={saveWarningDialogBox}
          close={handleCloseSaveWarningDialogBox}
        />

        <OptionalDocsDialogBox
          open={optionalDocumentDialogBox}
          close={handleCloseOptionalDocumentDialogBox}
          data={optionalDocData}
          deleteOptionalDocument={deleteOptionalDocument}
          optionLineIndex={optionLineIndex}
        />

        <DynamicOptionsDialog
          open={dynamicOptOpen}
          setOpen={setDynamicOptOpen}
          product={data}
          dealChange={dealChange}
          updateOption={handleDynamicLine}
          selectedLine={selectedRow}
        />

        <ApiLoaderComponent status={loading.status} data={loading.data} />
      </>
    );
  } else {
    return <LoadingComponent color={props.context.theme.sidebar.background} />;
  }
}
