import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";

import {
  DealBuilderApi,
  AddressAutocompleteComponent,
} from "@unity/components";

export const DynamicOptionsDialog = ({
  open,
  setOpen,
  product,
  updateOption,
  dealChange,
  selectedLine,
}) => {
  const [address, setAddress] = useState(null);
  const [error, setError] = useState(null);
  const [existingPostcode, setExistingPostcode] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (product?.api_model_data && product.api_model_data?.address) {
      setAddress(product.api_model_data.address);
    }
  }, [product?.api_model_data?.address]);

  useEffect(() => {
    if (address?.postcode) setExistingPostcode(address.postcode);
  }, [address]);

  const handleClose = () => {
    setOpen(false);
    setError(null);
    setAddress(null);
  };

  const handleClick = async () => {
    setLoading(true);
    try {
      if (address) {
        const suppliers = selectedLine.options
          ? selectedLine.options
              .filter((el) => el.has_api)
              .map((el, i) => ({ ...el.api_model_data }))
          : [];
        const query = {
          suppliers,
          ...product.api_model_data,
          ...address,
        };

        const res = await DealBuilderApi.getUnityProductAvailability(query);

        console.log("KSFDGL: ", JSON.stringify(res.data));

        if (res?.success) {
          const filteredOpts = selectedLine.options.filter((el) => el.has_api);
          filteredOpts.forEach((option, i) => {
            updateOption(
              { target: { name: "quotes", value: res.data[i] } },
              i,
              true
            );
          });
          handleClose();
        }
      } else {
        setError([["Must supply address"]]);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleAddress = (val) => {
    setAddress(val);
    setExistingPostcode(val.postcode);
    //put into product
    dealChange(
      { api_model_data: { ...product.api_model_data, address: val } },
      true
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Retrieve dynamic options for eligable product lines
      </DialogTitle>
      <DialogContent sx={{ p: 2 }}>
        <AddressAutocompleteComponent
          address={address}
          setAddress={handleAddress}
        />
        {Array.isArray(error) &&
          error.map((el) => (
            <Typography color="error.main" sx={{ mt: 1 }}>
              {el}
            </Typography>
          ))}
        {existingPostcode ? (
          <Typography>Address selected: {existingPostcode}</Typography>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        {loading ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            onClick={handleClick}
            disabled={!existingPostcode}
          >
            Get
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
