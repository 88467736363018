import React, { useState } from "react";
import ModuleButton from "../common/ModuleButton";
import Dialog from "@mui/material/Dialog";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ProductLineOverview from "./ProductLineOverview";
import {
  Button,
  DialogActions,
  DialogContent,
  MenuItem,
  Menu,
  TextField,
  Tooltip,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DatePicker from "../common/DatePicker";
import { DocumentVaultAPI } from "@unity/components";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Done from "@mui/icons-material/Done";

const OptionsMenu = ({ quotes, handleUpdateOption, optionIndex, disabled }) => {
  const [open, setOpen] = useState(null);

  const getPrettyQuote = (quote) => {
    const {
      supplier_name = "n/a",
      product_name = "n/a",
      term_months = "n/a",
      monthly_cost = 0,
    } = quote;
    const label = `£${monthly_cost} per month, ${term_months} months, ${product_name} (${supplier_name})`;
    return (
      <Tooltip title={label}>
        <span>{label}</span>
      </Tooltip>
    );
  };

  return (
    <>
      <Button disabled={disabled} onClick={(e) => setOpen(e.currentTarget)}>
        Options
      </Button>
      <Menu
        anchorEl={open}
        open={Boolean(open)}
        onClose={() => setOpen(null)}
        PaperProps={{
          style: {
            maxHeight: 300,
            maxWidth: 500,
          },
        }}
      >
        {quotes.map((el) => (
          <MenuItem onClick={() => handleUpdateOption(el, optionIndex)}>
            {getPrettyQuote(el)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const FullScreenDialogBox = (props) => {
  const handleInputChange = async (event, index) => {
    const files = event.target.files;
    let reader = new FileReader();
    for (let i = 0; i < files.length; i++) {
      reader.readAsDataURL(files[i]);
      reader.onload = (e) => {
        storeDocument(
          {
            base64: e.target.result,
            name: files[i].name,
            type: files[i].type,
            size: files[i].size,
          },
          index
        );
      };
    }
  };

  const storeDocument = async (obj, lineIndex) => {
    const up = await DocumentVaultAPI.storeDocument({
      documents: [obj],
      application_ref: props.context.auth.app_uuid,
      public: 0,
    });
    props.handleFileUploadedData(lineIndex, up);
  };

  const handleDynamicOptionClick = (data, index) => {
    // do something here, update product.line.option, i.e. material name is product name etc
    //map qutoe to [{name,value},....]
    const costs = props.getMarkup(data?.monthly_cost, props.data?.markup);
    const option = {
      material_name: data?.product_name,
      supplier_name: data?.supplier_name || data?.supplier.name,
      material_cost: data?.monthly_cost,
      unit_price: costs.work,
      markup: costs.markup,
      min_term: data?.term_months,
      valid_from: data?.valid_from,
      valid_to: data?.valid_to,
      selected: true,
    };
    const localData = Object.entries(option).map((el) => ({
      target: {
        name: el[0],
        value: el[1],
      },
    }));
    props.handleDynamicLine(localData, index, true);
  };

  return (
    <Dialog
      open={props.openFullBox}
      onClose={props.closeFullBox}
      fullWidth={true}
      maxWidth="90vw"
    >
      <DialogContent>
        <ProductLineOverview
          fullBoxInfo={props.data}
          getChargeData={props.getChargeData}
          getPeriodicData={props.getPeriodicData}
          uneditable={props.uneditable}
        />

        {props.data.dynamic ? (
          <>
            <div className="block">
              <div className="container-fluid">
                <div style={{ width: "100%" }}>
                  <h4>Outlines</h4>
                  <br></br>
                  {props.hasApi && !props.uneditable ? (
                    <Button onClick={props.getDynamicOptions}>
                      populate dynamic lines with options if available
                    </Button>
                  ) : null}
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Material Name</TableCell>
                          <TableCell>Supplier Name</TableCell>
                          {props.type == "builder" ? null : (
                            <TableCell align="right">Material Cost</TableCell>
                          )}

                          {props.type == "builder" ? null : (
                            <TableCell align="right">Markup</TableCell>
                          )}

                          <TableCell align="right">Unit Price</TableCell>
                          <TableCell align="right">Min term</TableCell>
                          <TableCell align="right">Valid from</TableCell>
                          <TableCell align="right">Valid to</TableCell>
                          <TableCell align="right">Status</TableCell>
                          <TableCell align="right">Options</TableCell>
                          <TableCell align="left">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {props.data.options &&
                          props.data.options.map(
                            (row, key) =>
                              !row.approved && (
                                <TableRow
                                  //key={row.description}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {row.material_name}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {row.supplier_name}
                                  </TableCell>

                                  {props.type == "builder" ? null : (
                                    <TableCell align="right">
                                      <TextField
                                        label=""
                                        name="material_cost"
                                        type="number"
                                        onChange={(e) =>
                                          props.calculateMarkup(e, key)
                                        }
                                        defaultValue={row.material_cost || ""}
                                        size="small"
                                        fullWidth
                                        InputProps={{
                                          readOnly: props.uneditable,
                                        }}
                                        error={
                                          props.errors &&
                                          props.errors[
                                            `lines.${key}.material_cost`
                                          ]
                                        }
                                        helperText={
                                          props.errors &&
                                          props.errors[
                                            `lines.${key}.material_cost`
                                          ]
                                            ? props.errors[
                                                `lines.${key}.material_cost`
                                              ]
                                            : ""
                                        }
                                      ></TextField>
                                    </TableCell>
                                  )}

                                  {props.type == "builder" ? null : (
                                    <TableCell align="right">
                                      <TextField
                                        label=""
                                        name="markup"
                                        type="number"
                                        onChange={(e) =>
                                          props.calculateMarkup(e, key)
                                        }
                                        defaultValue={row.markup || ""}
                                        size="small"
                                        fullWidth
                                        InputProps={{ readOnly: true }}
                                      ></TextField>
                                    </TableCell>
                                  )}

                                  <TableCell align="right">
                                    <TextField
                                      label=""
                                      name="unit_price"
                                      type="number"
                                      onChange={(e) =>
                                        props.handleDynamicLine(e, key)
                                      }
                                      value={row.unit_price || ""}
                                      size="small"
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                    ></TextField>
                                  </TableCell>

                                  <TableCell align="right">
                                    <TextField
                                      label=""
                                      name="min_term"
                                      type="number"
                                      defaultValue={row.min_term || ""}
                                      size="small"
                                      onChange={(e) =>
                                        props.handleDynamicLine(e, key)
                                      }
                                      fullWidth
                                      InputProps={{
                                        readOnly: props.uneditable,
                                      }}
                                    ></TextField>
                                  </TableCell>

                                  <TableCell align="right">
                                    <DatePicker
                                      index={key}
                                      date={row.valid_from}
                                      label="Valid from"
                                      name={"valid_from"}
                                      updateDealDate={
                                        props.updateValidFromDynamicDealDate
                                      }
                                      uneditable={props.uneditable}
                                    />
                                  </TableCell>
                                  <TableCell align="right">
                                    <DatePicker
                                      index={key}
                                      date={row.valid_to}
                                      label="Expiry date"
                                      name={"valid_to"}
                                      updateDealDate={
                                        props.updateValidToDynamicDealDate
                                      }
                                      uneditable={props.uneditable}
                                    />
                                  </TableCell>

                                  <TableCell align="right">
                                    {row.approved ? (
                                      <CheckCircleIcon color="success" />
                                    ) : row.selected ? (
                                      <Tooltip title="option selected">
                                        <Done />
                                      </Tooltip>
                                    ) : (
                                      <HelpIcon />
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {row.quotes && row.quotes.length > 0 ? (
                                      <OptionsMenu
                                        quotes={row.quotes}
                                        handleUpdateOption={
                                          handleDynamicOptionClick
                                        }
                                        optionIndex={key}
                                        disabled={props.uneditable}
                                      />
                                    ) : null}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ width: "200px" }}
                                  >
                                    {props.type == "builder" ||
                                    props.uneditable ? null : (
                                      <Tooltip title={"Upload"}>
                                        <Button
                                          variant="contained"
                                          component="label"
                                          onChange={(evt) =>
                                            handleInputChange(evt, key)
                                          }
                                        >
                                          <UploadFileIcon />
                                          <input type="file" hidden={true} />
                                        </Button>
                                      </Tooltip>
                                    )}

                                    <ModuleButton
                                      btnIcon="visibility"
                                      style={{
                                        backgroundColor: "#1976d2",
                                        marginLeft: "10px",
                                      }}
                                      onClick={() =>
                                        props.handleOpenOptionalDocumentDialogBox(
                                          key
                                        )
                                      }
                                      tip="View"
                                    />
                                  </TableCell>
                                </TableRow>
                              )
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </DialogContent>

      <DialogActions>
        <Button
          onClick={props.closeFullBox}
          style={{
            backgroundColor: props.context.theme.sidebar.background,
            color: "white",
            padding: "0.5rem 2.5rem 0.5rem 2.5rem",
          }}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default FullScreenDialogBox;
