import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  DealBuilderApi,
  AppHeaderComponent,
  LoadingComponent,
  ApiLoaderComponent,
} from "@unity/components";
import {
  CanCreate,
  CanRead,
  CanReadAll,
  CanUpdate,
  CanModuleAdmin,
  CanDelete,
} from "../services/Permissions";
import DealOverview from "../modules/DealOverview";
import ModuleFunctions from "../modules/ModuleFunctions";
import ProductDisplayTable from "../modules/ProductDisplayTable";
import ProductSelectionDialogBox from "../modules/ProductSelectionDialogBox";
import PersonDisplayTable from "../modules/PersonDisplayTable";
import PersonSelectionDialogBox from "../modules/PersonSelectionDialogBox";
import RequestChangeDialogBox from "../modules/RequestChangeDialogBox";
import DisplayInstructionsDialogBox from "../modules/DisplayInstructionsDialogBox";
import DatePicker from "../common/DatePicker";
import Typography from "@mui/material/Typography";
import { Box, Stack } from "@mui/material";

export default function DealSelect(props) {
  const [loading, setLoading] = useState({ status: false });
  const [fetchTrigger, setFetchTrigger] = useState(0); // This is used to determine if page needs to be re-rendered. The value doesn't matter, just the change!
  const [data, setData] = useState(false);
  const [errors, setErrors] = useState(false);
  const [personnel, setPersonnel] = useState(false);
  const [structure, setStructure] = useState(false);
  const [periods, setPeriods] = useState(false);
  const [charges, setCharges] = useState(false);
  const [methods, setMethods] = useState(false);
  const [vats, setVats] = useState(false);
  const [bus, setBus] = useState(false);
  const [salesOpp, setSalesOpp] = useState(false);

  const [teamMember, setTeamMember] = useState(false); //the ID to call assigned person Api when editing an existing user
  const [editTeamMemberState, setEditTeamMemberState] = useState(false); //Bool checker for the person dialog - checks if the user has already been assigned
  const [levelName, setLevelName] = useState(false); //Displays the current level name to the user when in the person dialog box
  const [requestChangeData, setRequestChangeData] = useState(false); //Product data passed to the change request dialog box

  const [productSelectionDialogBox, setProductSelectionDialogBox] =
    useState(false);
  const [personAssignmentDialogBox, setPersonAssignmentDialogBox] =
    useState(false);
  const [requestChangeDialogBox, setRequestChangeDialogBox] = useState(false);

  const [instructionsDialogBox, setInstructionsDialogBox] = useState(false);
  const [instructions, setIntructions] = useState(false);

  const date = new Date();

  let history = useHistory();
  const ownerPermission =
    (data.owner_id == props.context.auth.contact && !data.approved) ||
    data.retired;
  const isDealRetired =
    data.retired || (data.approved && new Date(data.valid_to) < date);

  const create = CanCreate("deal-builder");
  const read = CanRead("deal-builder");
  const edit = CanUpdate("deal-builder");
  const admin = CanModuleAdmin("deal-builder");

  const dealChange = (data) => {
    setData((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const closeInstructionsDialogBox = () => {
    setInstructionsDialogBox(false);
    setIntructions(false);
  };

  const openInstructionsDialogBox = (data) => {
    setIntructions(data.instructions);
    setInstructionsDialogBox(true);
  };

  const handleProductSelectionDialogBox = () => {
    productSelectionDialogBox
      ? setProductSelectionDialogBox(false)
      : setProductSelectionDialogBox(true);
  };

  const openRequestChangeDialogBox = (obj) => {
    setRequestChangeData(obj);
    setRequestChangeDialogBox(true);
  };

  const closeRequestChangeDialogBox = () => {
    setRequestChangeData(false);
    setRequestChangeDialogBox(false);
  };

  const openPersonSelectionDialogBox = (data) => {
    setEditTeamMemberState(false);
    setTeamMember(data);
    setPersonAssignmentDialogBox(true);
  };

  const closePersonSelectionDialogBox = () => {
    setLevelName(false);
    setEditTeamMemberState(false);
    setPersonAssignmentDialogBox(false);
    setTeamMember(false);
  };

  const editPersonSelectionDialogBox = (data) => {
    setLevelName(data.level_name);
    setEditTeamMemberState(true);
    setTeamMember(data);
    setPersonAssignmentDialogBox(true);
  };

  const handleLinkClick = (event, id, path) => {
    event.preventDefault();
    history.push({
      pathname: `/deal-builder/${path}/${id}`,
    });
  };

  const teamMemberChange = (data) => {
    setTeamMember((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const editTeamMember = (newValue) => {
    const newData = { ...teamMember };
    newData[newValue.target.name] = newValue.target.value;
    setTeamMember(newData);
  };

  const triggerChange = () => {
    setFetchTrigger(fetchTrigger + 1);
  };

  const personnelChange = (data) => {
    setPersonnel(data);
  };

  const structureChange = (data) => {
    setStructure(data);
  };

  const periodsChange = (data) => {
    setPeriods(data);
  };

  const vatsChange = (data) => {
    setVats(data);
  };

  const chargesChange = (data) => {
    setCharges(data);
  };

  const methodsChange = (data) => {
    setMethods(data);
  };

  const busChange = (data) => {
    setBus(data);
  };

  const salesOppChange = (data) => {
    setSalesOpp(data);
  };

  const handleDealSave = async () => {
    setLoading({ status: true, data: "Updating your Deal, Please Wait...." });

    const res = await DealBuilderApi.updateDeal(
      props.route.match.params.id,
      data
    );

    if (res.success) {
      dealChange({ changed: false });
      setLoading({ status: false });
    } else {
      if (res.errors) {
        setErrors(res.errors);
        setLoading({
          status: true,
          data: "Validation Errors!, Please Wait....",
        });
        setTimeout(() => {
          setLoading({ status: false });
        }, 3000);
      } else {
        setLoading({ status: true, data: res.message });
        setTimeout(() => {
          history.push("/deal-builder/index");
          setLoading({ status: false });
        }, 3000);
      }
    }
  };

  const handleProductSelectToggle = async (obj) => {
    setLoading({ status: true, data: "Selecting Product, Please Wait...." });
    const res = await DealBuilderApi.liveProductSelectToggle(obj.id, obj);

    if (res.success) {
      triggerChange();
      setLoading({ status: true, data: "Successfully updating product" });
      setTimeout(() => {
        setLoading({ status: false });
      }, 3000);
    } else {
      if (res.errors) {
        setErrors(res.errors);
        setLoading({
          status: true,
          data: "Validation Errors!, Please Wait....",
        });
        setTimeout(() => {
          setLoading({ status: false });
        }, 3000);
      } else {
        setLoading({ status: true, data: res.message });
        setTimeout(() => {
          history.push("/deal-builder/index");
          setLoading({ status: false });
        }, 3000);
      }
    }
  };

  useEffect(() => {
    ModuleFunctions.getPersonnel({ personnelChange: personnelChange });
    ModuleFunctions.getPeriodicCodes({ periodsChange: periodsChange });
    ModuleFunctions.getChargeCodes({ chargesChange: chargesChange });
    ModuleFunctions.getPaymentMethods({ methodsChange: methodsChange });
    ModuleFunctions.getVatRates({ vatsChange: vatsChange });
    ModuleFunctions.getBusinesses({ change: busChange });
    ModuleFunctions.getSalesOpps({ salesOppChange: salesOppChange });
    ModuleFunctions.getDeal({
      id: props.route.match.params.id,
      dealChange: dealChange,
    });
    ModuleFunctions.getStructure({ structureChange: structureChange });
  }, [fetchTrigger]);

  if (edit && data && structure && personnel) {
    return (
      <>
        <AppHeaderComponent
          saveBtn={data.changed ? handleDealSave : null}
          context={props.context}
          theme={props.context.theme}
          name="deal-builder"
          subpage="edit"
          spacer={true}
        />

        <ProductSelectionDialogBox
          open={productSelectionDialogBox}
          close={handleProductSelectionDialogBox}
          data={data.live_products}
          deal_id={props.route.match.params.id}
          dealChange={dealChange}
          triggerChange={triggerChange}
        />

        <DealOverview
          context={props.context}
          type="edit"
          create={create}
          read={read}
          edit={edit}
          data={data}
          errors={errors}
          personnel={personnel}
          structure={structure}
          periods={periods}
          charges={charges}
          methods={methods}
          vats={vats}
          bus={bus}
          salesOpp={salesOpp}
          dealChange={dealChange}
          ownerPermission={ownerPermission}
        />
        <Box sx={{ p: 1, mt: 1, mb: 1 }}>
          <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
            <DatePicker
              date={data.valid_from}
              label="Valid from"
              name={"valid_from"}
              uneditable={true}
            />
            <DatePicker
              date={data.valid_to}
              label="Expiry date"
              name={"valid_to"}
              uneditable={true}
            />
            <Typography variant="h6">
              dates are calculated from the products!
            </Typography>
          </Stack>
        </Box>
        <DisplayInstructionsDialogBox
          open={instructionsDialogBox}
          close={closeInstructionsDialogBox}
          instructions={instructions}
        />

        <PersonDisplayTable
          data={data.team_members}
          deal_id={props.route.match.params.id}
          openDialog={openPersonSelectionDialogBox}
          personnel={personnel}
          edit={edit}
          triggerChange={triggerChange}
          editPersonSelectionDialogBox={editPersonSelectionDialogBox}
          ownerPermission={ownerPermission}
          isDealRetired={isDealRetired}
          openInstructionsDialogBox={openInstructionsDialogBox}
        />

        <ProductDisplayTable
          deal_id={props.route.match.params.id}
          data={data.live_products}
          handleLinkClick={handleLinkClick}
          handleProductSelectionDialogBox={handleProductSelectionDialogBox}
          bus={bus}
          triggerChange={triggerChange}
          context={props.context}
          openRequestChangeDialogBox={openRequestChangeDialogBox}
          handleProductSelectToggle={handleProductSelectToggle}
          personnel={personnel}
          ownerPermission={ownerPermission}
          isDealRetired={isDealRetired}
        />

        <PersonSelectionDialogBox
          deal_id={props.route.match.params.id}
          personnel={personnel}
          context={props.context}
          openDialog={personAssignmentDialogBox}
          closeDialog={closePersonSelectionDialogBox}
          triggerChange={triggerChange}
          edit={edit}
          teamMember={teamMember} // Used to call getTeamMemeber Api for already assigned users
          teamMemberChange={teamMemberChange}
          editTeamMember={editTeamMember}
          editTeamMemberState={editTeamMemberState}
          levelName={levelName}
        />

        <RequestChangeDialogBox
          open={requestChangeDialogBox}
          close={closeRequestChangeDialogBox}
          data={requestChangeData}
          triggerChange={triggerChange}
        />

        <ApiLoaderComponent status={loading.status} data={loading.data} />
      </>
    );
  } else {
    return <LoadingComponent color={props.context.theme.sidebar.background} />;
  }
}
